import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

import solarImage from '../images/solar.jpg'
import sun3Image from '../images/sun3.png'

const Home = () => {
  const navigate = useNavigate(); 

  const handleButtonClick = () => {
    navigate('/despre-noi'); 
    window.scrollTo(0,0);
  };

  return (
    <div className='home'>
      <Header />
      <main className='home2'>
        <br/><br/><br/><br/><h1>Soarele straluceste pentru toti!</h1><br/><br/>
        <div className='sun'><img src={sun3Image}/></div><br/>
        <p>
          Bine ați venit la SC SHIMANO SRL! - Energie sustenabilă pentru viitor!<br/><br/><br/><br/>
          De ce să alegeți soluțiile noastre de panouri solare fotovoltaice?<br/><br/>
          <ul>
          <li>La SC SHIMANO SRL, suntem dedicați să aducem lumina soarelui direct în casele și afacerile dumneavoastră.</li><br/> 
          <li>Cu o experiență vastă în montarea și întreținerea panourilor solare fotovoltaice, oferim soluții personalizate care vă permit să economisiți energie și bani, contribuind totodată la protejarea mediului.</li></ul><br/><br/><br/>

          Ce vă oferim: <br/>
          <ul>
          <li><strong>Consultanță Profesională</strong> - Echipa noastră de experți vă va ajuta să alegeți soluția optimă pentru nevoile energetice ale locuinței sau afacerii dumneavoastră.</li><br/>
          <li><strong>Montaj Rapid și Eficient</strong> - Folosim cele mai noi tehnologii pentru a asigura o instalare sigură și durabilă.</li><br/>
          <li><strong>Întreținere și Suport</strong> - Suntem alături de dumneavoastră și după instalare, oferind servicii complete de întreținere și suport tehnic.</li>
          </ul><br/><br/>
          Avantajele energiei solare:<br/><br/>
          <ul>
          <li><strong>Reducerea Facturilor la Energie</strong> - Cu panourile solare, puteți genera propria energie, scăzând astfel considerabil costurile lunare.</li><br/>
          <li><strong>Independență Energetică</strong> - Profitați de resursele regenerabile și deveniți mai puțin dependent de rețelele de energie tradiționale.</li><br/>
          <li><strong>Impact Ecologic Pozitiv</strong> - Contribuiți la reducerea emisiilor de carbon și la un viitor mai verde.</li>
          </ul><br/><br/>
          Etapele instalarii:<br/>
          <ul>
          <li><strong>Evaluare și Consultanță</strong> - Începem cu o evaluare detaliată a nevoilor și a locației dumneavoastră.</li><br/>
          <li><strong>Proiectare și Planificare</strong> - Creăm un plan personalizat care să maximizeze eficiența energetică.</li><br/>
          <li><strong>Instalare</strong> - Echipa noastră calificată se ocupă de montajul rapid și sigur al panourilor solare.</li><br/>
          <li><strong>Monitorizare și Întreținere</strong> - Oferim soluții de monitorizare pentru a asigura performanța optimă a sistemului.</li><br/><br/>
          </ul>
          Investiți în viitor cu SC SHIMANO SRL!<br/>
          Contactați-ne astăzi pentru o consultație gratuită și faceți primul pas către un viitor mai verde și mai eficient energetic!
        </p><br/><br/>

        <button className='btn' onClick={handleButtonClick}>Afla mai multe</button><br/><br/><br/><br/><br/><br/><br/><br/>
        <div className='solar'><img src={solarImage} alt="Poza" /></div><br/>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
