import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import fotovolImage from '../images/fotovol.jpg'
import invertorImage from '../images/invertor.jpg'
import sun3Image from '../images/sun3.png'
import profilImage from '../images/profil.jpg'
import profil2Image from '../images/profil2.jpg'
import profil3Image from '../images/profil3.jpg'
import suportImage from '../images/suport.jpg'
import suport2Image from '../images/suport2.jpg'
import suport3Image from '../images/suport3.jpg'
import suport4Image from '../images/suport4.jpg'
import clemaImage from '../images/clema.jpg'
import clema2Image from '../images/clema2.jpg'
import mufaImage from '../images/mufa.jpg'
import fotovol2Image from '../images/fotovol2.jpg'
import invertor2Image from '../images/invertor2.jpg'
import invertor3Image from '../images/invertor3.jpg'
import acumulatorImage from '../images/acumulator.jpg'

const Produse = () => (
  <div>
    <Header />
    <main>
    <br/><br/><br/><br/><h1>Produse</h1>
    <div className='sun'><img src={sun3Image}/></div><br/>
      <p>PRODUSE:<br/><br/>
1.	Panouri fotovoltaice<br/><br/>
    <div className='products'><img src={fotovolImage}/><img src={fotovol2Image}/></div>

    <br/><br/>
2.	Invertoare<br/><br/>
    <div className='products'><img src={invertorImage}/><img src={invertor2Image}/><img src={invertor3Image}/></div>

    <br/><br/>
3.	Accesorii <br/>&nbsp;&nbsp;  - profile aluminiu <br/><br/><div className='products'><img src={profilImage}/><img src={profil2Image}/><img src={profil3Image}/></div><br/>
                   &nbsp;&nbsp;  - suporti de montaj pentru acoperis cu tigla<br/><br/> <div className='products'><img src={suport3Image}/></div><br/>&nbsp;&nbsp;
                   - suporti de montaj pentru acoperis cu tabla<br/><br/> <div className='products'><img src={suportImage}/><img src={suport2Image}/></div><br/>&nbsp;&nbsp;
                   - suporti de montaj pentru acoperis sandwich <br/><br/> <div className='products'><img src={suport4Image}/></div><br/><br/>
                   &nbsp;&nbsp;  - cleme de montaj<br/><br/><div className='products'><img src={clemaImage}/><img src={clema2Image}/></div><br/>
                   &nbsp;&nbsp;  - suruburi, piulite<br/>
                   &nbsp;&nbsp;  - cablu solar<br/>
                   &nbsp;&nbsp;  - mufe MC4<br/><br/><div className='products'><img src={mufaImage}/></div><br/>
                   &nbsp;&nbsp;  - sigurante fuzibile<br/><br/>

4.	Acumulatori pentru instalatii fotovoltaice <br/><br/><div className='products'><img src={acumulatorImage}/></div><br/><br/>
5.	Device-uri pentru instalatii fotovoltaice<br/>      &nbsp;&nbsp; - Smart Power Sensor <br/>
&nbsp;&nbsp; - Power Module<br/>
&nbsp;&nbsp; - Back-up System<br/>
&nbsp;&nbsp; - Optimizer <br/>
&nbsp;&nbsp; - Smart Dongle<br/><br/>

6.	 Sisteme fotovoltaice  complecte<br/>&nbsp;&nbsp;   - monofazate  de 3 , 4 , 5 , 6 și 8 kW <br/>
&nbsp;&nbsp;   - trifazate de 5 , 6 , 8 , 10 , 12 , 15 , 17 , 20 , 25 și 30 kW <br/><br/><br/><br/>
</p>
    </main>
    <Footer />
  </div>
);

export default Produse;