import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import sun3Image from '../images/sun3.png';

const CasaVerde = () => (
  <div>
    <Header />
    <main>
    <br/><br/><br/><br/><br/><h1>Casa Verde</h1><br/><br/>
    <div className='sun'><img src={sun3Image}/></div><br/>
    <h1>Documente necesare pentru inscrierea in programul Casa Verde Fotovoltaice:</h1>
    
    <ul className='documents'>
      <li><strong>Act de identitate</strong> valabil la momentul înscrierii și cu aceeași adresă ca locul de implementare;</li>
      <li><strong>Extras de carte funciară actualizat</strong> emis cu maximum 60 de zile înainte de data înscrierii;</li>
      <li><strong>Certificat de atestare fiscala (ANAF) </strong> emis cu maximum 30 de zile înainte de data înscrierii;</li>
      <li><strong>Certificat de atestare fiscala</strong>  ( buget local ) emis cu maximum 90 de zile inainte de data înscrierii; </li>
      <li><strong>Extras de carte funciară colectivă</strong> – numai pentru imobilele care conțin două unități individuale pe aceeași carte funciară.</li>
    </ul>
    <p>
      Toate documentele vor fi emise pe numele celui care accesează programul!
    </p>
    
      <p><br/><br/><br/></p>
    </main>
    <Footer />
  </div>
);

export default CasaVerde;
